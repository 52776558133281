import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { Modal } from "react-responsive-modal";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { toggleLoader, getTutors } from "../../store/actions/Auth";

class Learn2Earn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
    };
  }

  handleQuantity = () => {
  };

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  componentDidMount(){
    this.props.toggleLoader({message:"Load tutors...",status:true});
    this.props.getTutors({});
  }

  render() {
    let { sticky } = this.state;
    let {tutors} = this.props;
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        <Navbar/>

        <div className="admin-wrapper">
          <Sidebar/>

          <div className="inner">
            <div className="table-wrap">
              <table responsive="md">
                <thead>
                  <tr>
                    <th>Wallet Address</th>
                    {/* <th>Soul Bound</th> */}
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {tutors && tutors.length > 0 ?
                  tutors.map((items,idx)=>
                  <tr>
                    <td>{items['publicAddress'] ? items['publicAddress'] :"-"}</td>
                    {/* <td>{items['soulbound'] ? items['soulbound'].slice(0,20)+ :"-"}</td> */}
                    <td>{items['firstName'] ? items['firstName'] :"-"}</td>
                    <td>{items['email'] ? items['email'] :"-"}</td>
                    <td>
                      <button>
                      {items['status'] ? items['status'] :"-"}
                      </button>
                    </td>
                  </tr>
                  )
                  :
                  <div>
                    <td>Tutors Not Found</td>
                  </div>
                  }

                  {/* <tr>
                    <td>Dummy text</td>
                    <td>Dummy text</td>
                    <td>Dummy text</td>
                    <td>Dummy text</td>
                    <td>Dummy text</td>
                    <td>
                      <button className="approved">
                      Approved
                      </button>
                    </td>
                  </tr> */}
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, getTutors };

const mapStateToProps = ({ Auth }) => {
  let { tutors } = Auth;
  return { tutors };
};

export default connect(mapStateToProps, mapDispatchToProps)(Learn2Earn);
