import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { toggleLoader,getQuizQuestions, editQuizQuestions, addQuizQuestions, deleteQuizQuestions } from "../../store/actions/Auth";
const correct = "/images/correct-icon.png";

class EditQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
            isOpen: false,
            setQuiz:"Javascript",
            setCategory:"Beginner",
            setPassingPercentage:"Beginner",
            question:"",
            answerA:"",
            answerB:"",
            answerC:"",
            answerD:"",
            correctAnswer:"",
            itemId:"",
            quizs:[{ 
              question:"",
              answerA:"",
              answerB:"",
              answerC:"",
              answerD:"",
              correctAnswer:"",
              quiz: this.props.match.params.id
            }],
            createQuizModal: false,
            mcqsSetupModal: false
    };
  }

  addInputField = ()=>{
    let {quizs} = this.state;
    this.setState({quizs:[...quizs, { 
        question:"",
        answerA:"",
        answerB:"",
        answerC:"",
        answerD:"",
        correctAnswer:"",
        quiz: this.props.match.params.id
    }]})
  }

  removeFormFields = (index)=>{
      let {quizs} = this.state;
      const rows = [...quizs];
      rows.splice(index, 1);
      this.setState({quizs:rows})
  }

  handleChangeForm = (evnt,index)=>{
      let {quizs} = this.state;
      const { name, value } = evnt.target;
      const list = [...quizs];
      list[index][name] = value;
      this.setState({quizs:list})
  }

  publishQuiz = () => {
    let {quizs} = this.state;
    if(quizs.length == 0) return EventBus.publish("error", "Please add quiz questions");
    for (let index = 0; index < quizs.length; index++) {
        const element = quizs[index];
        if(!element['question'] && !element['question'].trim("") == true) return EventBus.publish("error", `Please add question no ${index+1}`);
        if(!element['answerA'] && !element['answerA'].trim("") == true) return EventBus.publish("error", `Please add option A of question ${index+1}`);
        if(!element['answerB'] && !element['answerB'].trim("") == true) return EventBus.publish("error", `Please add option B of question ${index+1}`);
        if(!element['answerC'] && !element['answerC'].trim("") == true) return EventBus.publish("error", `Please add option C of question ${index+1}`);
        if(!element['answerD'] && !element['answerD'].trim("") == true) return EventBus.publish("error", `Please add option D of question ${index+1}`);
        if(!element['correctAnswer'] && !element['correctAnswer'].trim("") == true) return EventBus.publish("error", `Please add correct option of question ${index+1}`);
    }
    let quizId =  this.props.match.params.id;
    this.props.toggleLoader({message:"Quiz questions submitting...", status: true});
    this.props.addQuizQuestions({quizId, quizs});
}

  updateQuizQuestion = (itemId,question,answerA,answerB,answerC,answerD,correctAnswer) => {
    this.setState({itemId,question,answerA,answerB,answerC,answerD,correctAnswer,mcqsSetupModal:true})
  };

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  updateNow = () => {
    let {itemId,question,answerA,answerB,answerC,answerD,correctAnswer} = this.state;
    let questionId = itemId;
    let quizId = this.props.match.params.id;
    if(!question && !question.trim("") == true) return EventBus.publish("error", "Please add question"); 
    if(!answerA && !answerA.trim("") == true) return EventBus.publish("error", "Please add option A"); 
    if(!answerB && !answerB.trim("") == true) return EventBus.publish("error", "Please add option B"); 
    if(!answerC && !answerC.trim("") == true) return EventBus.publish("error", "Please add option C"); 
    if(!answerD && !answerD.trim("") == true) return EventBus.publish("error", "Please add option D"); 
    if(!correctAnswer && !correctAnswer.trim("") == true) return EventBus.publish("error", "Please select correct option"); 
    this.props.toggleLoader({message:"Question updating...", status: true});
    this.props.editQuizQuestions({question,answerA,answerB,answerC,answerD,correctAnswer,questionId,quizId});
  }

  deleteQuestion = (questionId) => {
    let quizId = this.props.match.params.id;
    this.props.toggleLoader({message:"Question deleting...", status: true});
    this.props.deleteQuizQuestions({questionId,quizId});
  }

  componentDidMount(){
    if(this.props.match.params.id){
      this.props.toggleLoader({message:"Get quiz questions...", status:true});
      this.props.getQuizQuestions({quizId:this.props.match.params.id})
    }
  }

  componentDidUpdate(prevProps){
    let {quizQuestions} = this.props;
    if(prevProps.quizQuestions !== quizQuestions){
      this.setState({mcqsSetupModal:false,createQuizModal:false});
    }
  }

  render() {
    let { question,answerA,answerB,answerC,answerD,correctAnswer, quizs } = this.state;
    let {quizQuestions} = this.props;
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        <Navbar/>

        <div className="admin-wrapper">
          <Sidebar/>

          <div className="inner">
            <div className="mb-3 head">
              <h2>Edit Quiz</h2>
              <button className="common-btn"  onClick={() => this.setState({createQuizModal:true})} >Add Question</button>
            </div>

            <div className="table-wrap edit-questions">
              <table responsive="md">
                <thead>
                  <tr>
                    <th>Questions</th>
                    <th>Answer A</th>
                    <th>Answer B</th>
                    <th>Answer C</th>
                    <th>Answer D</th>
                    <th>Correct Answer</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {quizQuestions && quizQuestions.length > 0 && quizQuestions.map(item=>
                  <tr>
                    <td>{item['question']}</td>
                    <td>{item['answerA']}</td>
                    <td>{item['answerB']}</td>
                    <td>{item['answerC']}</td>
                    <td>{item['answerD']}</td>
                    <td>{item['correctAnswer']}</td>
                    <td>
                      <button onClick={() => this.updateQuizQuestion(item['_id'],item['question'],item['answerA'],item['answerB'],item['answerC'],item['answerD'],item['correctAnswer'])}>Edit</button>
                      <button className="dlt" onClick={() => this.deleteQuestion(item['_id'])}>Delete</button>
                    </td>
                    
                  </tr>
                  )}
                </tbody>

                
              </table>
            </div>

          </div>
        </div>
        {/*  update items */}
        <Modal
            open={this.state.mcqsSetupModal}
            onClose={() => this.setState({mcqsSetupModal:false})}
            classNames={{
                modal: `common-modal mcqs-setup`,
            }}
            center
            >
        <div class="modal-header">
            <button type="button" class="close" onClick={() => this.setState({mcqsSetupModal:false})}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="form-main">
                    <div class="inner">
                        {/* <form> */}
                            <div className="head justify-content-center">
                                <div className="title-wrap">
                                    <h1>Update MCQs</h1>

                                    <p>Select correct option before adding question to the quiz.</p>
                                </div>

                            </div>

                                <div className="row">
                                    <div class="form-group col-12">
                                        <textarea className="form-control" name="question" value={question || ""} onChange={(e)=>this.setState({question:e.target.value})} placeholder="Write question here"></textarea>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="A">A</label>
                                        <input id="A" type="text" class="form-control" placeholder="Write option A here" name="answerA" value={answerA || ""} onChange={(e)=>this.setState({answerA:e.target.value})}/>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="B">B</label>
                                        <input id="B" type="text" class="form-control" placeholder="Write option B here" name="answerB" value={answerB || ""} onChange={(e)=>this.setState({answerB:e.target.value})}/>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="C">C</label>
                                        <input id="C" type="text" class="form-control" placeholder="Write option C here" name="answerC" value={answerC || ""} onChange={(e)=>this.setState({answerC:e.target.value})}/>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="D">D</label>
                                        <input id="D" type="text" class="form-control" placeholder="Write option D here" name="answerD" value={answerD || ""} onChange={(e)=>this.setState({answerD:e.target.value})}/>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="E"><img className="blue" src={correct}/></label>
                                        <select id="E" class="form-control" name="correctAnswer" value={correctAnswer || ""} onChange={(e)=>this.setState({correctAnswer:e.target.value})}>
                                            <option value="" selected disabled>Select correct option</option>
                                            <option value={answerA}>{answerA}</option>
                                            <option value={answerB}>{answerB}</option>
                                            <option value={answerD}>{answerD}</option>
                                            <option value={answerC}>{answerC}</option>
                                        </select>
                                    </div>
                                </div>

                            <div className="bottom flex-center">
                                <button className="common-btn update" onClick={this.updateNow}>Update question</button>
                            </div>
                        {/* </form> */}
                    </div>
            </div>
        </div>
        </Modal>
        
        {/*  add new items */}
          <Modal
                    open={this.state.createQuizModal}
                    onClose={() => this.setState({createQuizModal:false})}
                    classNames={{
                        modal: `common-modal mcqs-setup`,
                    }}
                    center
                    >
                <div class="modal-header">
                    <button type="button" class="close" onClick={() => this.setState({createQuizModal:false})}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="form-main">
                            <div class="inner">
                                    <div className="head">
                                        <div className="empty"></div>

                                        <div className="title-wrap">
                                            <h1>Add MCQs</h1>

                                            <p>Select correct option before adding question to the quiz.</p>
                                        </div>

                                        <button className="common-btn" onClick={()=>this.publishQuiz()}>Publish quiz</button>
                                    </div>
                                    <div className="row-wrap">
                                    {quizs.map((data, index)=>{
                                    const {question,answerA, answerB, answerC, answerD, correctAnswer}= data;
                                    return(
                                        <div className="row" key={index}>
                                             <div class="form-group col-12">
                                                            <h5 style={{color:"white"}}>Question No {index+1}</h5>
                                                </div>
                                            <div class="form-group col-12">
                                                <textarea className="form-control" name="question" value={question || ""} onChange={(e)=>this.handleChangeForm(e,index)} placeholder="Write question here"></textarea>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="A">A</label>
                                                <input id="A" type="text" class="form-control" placeholder="Write option A here" name="answerA" value={answerA || ""} onChange={(e)=>this.handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="B">B</label>
                                                <input id="B" type="text" class="form-control" placeholder="Write option B here" name="answerB" value={answerB || ""} onChange={(e)=>this.handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="C">C</label>
                                                <input id="C" type="text" class="form-control" placeholder="Write option C here" name="answerC" value={answerC || ""} onChange={(e)=>this.handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="D">D</label>
                                                <input id="D" type="text" class="form-control" placeholder="Write option D here" name="answerD" value={answerD || ""} onChange={(e)=>this.handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="E"><img className="blue" src={correct}/></label>
                                                <select id="E" class="form-control" name="correctAnswer" value={correctAnswer || ""} onChange={(e)=>this.handleChangeForm(e,index)}>
                                                    <option value="" selected disabled>Select correct option</option>
                                                    <option value={answerA}>{answerA}</option>
                                                    <option value={answerB}>{answerB}</option>
                                                    <option value={answerC}>{answerC}</option>
                                                    <option value={answerD}>{answerD}</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6 d-flex justify-content-end align-items-center">
                                                {(quizs.length !== 1) ? <button className="delete-btn common-btn"  onClick={()=>this.removeFormFields(index)}>Delete question</button>:""}
                                            </div>
                                        </div>
                                    )
                                    })}
                                    </div>

                                    <div className="bottom flex-center">
                                        <button className="common-btn" onClick={this.addInputField}>Add question</button>
                                    </div>
                            </div>
                    </div>
                </div>
          </Modal>

      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader, getQuizQuestions, editQuizQuestions, addQuizQuestions, deleteQuizQuestions };

const mapStateToProps = ({ Auth }) => {
  let {quizQuestions} = Auth;
  return { quizQuestions};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditQuestions);
