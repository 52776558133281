import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { toggleLoader } from "../../store/actions/Auth";

class EditQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
    };
  }

  handleQuantity = () => {
  };

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  quiz = () => {
    window.location.href = '/quiz'
  }

  render() {
    let { sticky } = this.state;
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        <Navbar/>

        <div className="admin-wrapper">
          <Sidebar/>

          <div className="inner">
            <h2 className="mb-3">Edit Quiz</h2>
            
            <div className="table-wrap">
              <table responsive="md">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Dummy text</td>
                    <td><a href="/edit-questions">Edit</a></td>
                  </tr>

                  <tr>
                    <td>Dummy text</td>
                    <td><a href="/edit-questions">Edit</a></td>
                  </tr>

                  <tr>
                    <td>Dummy text</td>
                    <td><a href="/edit-questions">Edit</a></td>
                  </tr>

                  <tr>
                    <td>Dummy text</td>
                    <td><a href="/edit-questions">Edit</a></td>
                  </tr>
                </tbody>

              </table>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader };

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return { };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditQuiz);
