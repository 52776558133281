import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import { toggleLoader, login } from "../../store/actions/Auth";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      password:":"
    };
  }

  submitLogin=(e)=>{
    e.preventDefault();
    let {email, password} = this.state;
    if(!email && !email.trim("") == true) return EventBus.publish("error", "Please enter correct email");
    if(!password && !password.trim("") == true) return EventBus.publish("error", "Please enter correct password");
    this.props.toggleLoader({message:"Admin Login...",status:true});
    this.props.login({email,password});
  }

  render() {
    let { value1, value2, value3, value4, value5, sticky, quizState } = this.state;
    console.log("*** quizState", quizState);
    return (
      <div className="wrapper" onWheel={this.onScroll}>
        {/* <Navbar sticky={sticky} /> */}

        <section class="form-main sign-up sign-in">
            <div class="wrap">
              <div className="inner">
                <h1>Sign in</h1>
                <form onSubmit={this.submitLogin}>       
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Username" onChange={e=>this.setState({email:e.target.value})}/>
                    </div>
                    
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Password" onChange={e=>this.setState({password:e.target.value})}/>
                    </div>

                    {/* <div class="form-group forgot-pwd">
                        <a href="#">Forgot password?</a> */}
                    {/* </div> */}

                    <div class="form-group">
                        <input type="submit" value="Sign in" name="submit"/>
                    </div>
                </form>
                {/* <p>Dont have an account? <a href="/sign-up">Sign up</a></p> */}
              </div>
            </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader,login };

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return { };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
