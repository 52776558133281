import axios from 'axios';
import { web3 } from '../web3';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { setHeader, setToken } from "../axios";
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setLogin, toggleLoader, getQuiz, setQuizQuestions, setTutors, getCategory} from '../actions/Auth';

/*========== ASSESSMENT FUNCTIONS =============*/

export function* login({ payload }) {
  const { error, response } = yield call(postCall, { path: "/loginSuperAdmin", payload });
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    yield put(toggleLoader({status:false}));
    if (decoded["role"] !== "superAdmin") {
      EventBus.publish("error", "Can't login through other account ");
      return;
    }
    setToken(response["data"]["body"]["token"]);
    yield put(setLogin(response["data"]["body"]["token"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* createQuiz({ payload }) {
  const { error, response } = yield call(postCall, { path: "/createQuiz", payload });
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(getQuiz(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* editQuiz({ payload }) {
  const { error, response } = yield call(postCall, { path: "/editQuiz", payload });
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(getQuiz(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* getAllQuiz() {
  const { error, response } = yield call(getCall, "/getQuiz");
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(getQuiz(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* deleteQuiz({payload}) {
  const { error, response } = yield call(getCall, `/deleteQuiz/${payload['id']}`);
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(getQuiz(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* getQuizQuestions({payload}) {
  const { error, response } = yield call(getCall, `/getQuizQuestions/${payload['quizId']}`);
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(setQuizQuestions(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* editQuizQuestions({ payload }) {
  const { error, response } = yield call(postCall, { path: "/editQuizQuestion", payload });
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(setQuizQuestions(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* deleteQuizQuestions({ payload }) {
  const { error, response } = yield call(getCall, `/deleteQuizQuestion/${payload['questionId']}/${payload['quizId']}`);
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(setQuizQuestions(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* addQuizQuestions({ payload }) {
  const { error, response } = yield call(postCall, { path: "/addQuizQuestion", payload });
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(setQuizQuestions(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* getTutors({ payload }) {
  const { error, response } = yield call(getCall, `/getTutors`);
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(setTutors(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};


export function* setCategory({ payload }) {
  const { error, response } = yield call(postCall, { path: "/addCategory", payload });
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(getCategory(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* getAllCategory({ payload }) {
  const { error, response } = yield call(getCall,"/getCategory");
  if (error){ 
    yield put(toggleLoader({status:false}));
    // EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(getCategory(response["data"]["body"]));
    // EventBus.publish("success", response['data']['message']);
  }
};

export function* updateCategory({ payload }) {
  const { error, response } = yield call(postCall, { path: "/updateCategory", payload });
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(getCategory(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

export function* deleteCategory({ payload }) {
  const { error, response } = yield call(postCall, { path: "/deleteCategory", payload });
  if (error){ 
    yield put(toggleLoader({status:false}));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response){
    yield put(toggleLoader({status:false}));
    yield put(getCategory(response["data"]["body"]));
    EventBus.publish("success", response['data']['message']);
  }
};

function* actionWatcher() {
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
