import { all, takeEvery } from 'redux-saga/effects';
import { login,createQuiz,getAllQuiz,deleteQuiz, editQuiz,getQuizQuestions,editQuizQuestions,addQuizQuestions,deleteQuizQuestions,getTutors, setCategory, updateCategory, deleteCategory, getAllCategory} from './Auth';

export default function* rootSaga() {
  yield all([
    yield takeEvery('LOGIN', login),
    yield takeEvery('SET_QUIZ', createQuiz),
    yield takeEvery('EDIT_QUIZ', editQuiz),
    yield takeEvery('SET_ALL_QUIZ', getAllQuiz),
    yield takeEvery('DELETE_QUIZ', deleteQuiz),
    yield takeEvery('GET_QUIZ_QUESTIONS', getQuizQuestions),
    yield takeEvery('ADD_QUIZ_QUESTIONS', addQuizQuestions),
    yield takeEvery('EDIT_QUIZ_QUESTIONS', editQuizQuestions),
    yield takeEvery('DELETE_QUIZ_QUESTIONS', deleteQuizQuestions),
    yield takeEvery('GET_TUTORS', getTutors),

    yield takeEvery('SET_CATEGORY', setCategory),
    yield takeEvery('UPDATE_CATEGORY', updateCategory),
    yield takeEvery('DELETE_CATEGORY', deleteCategory),
    yield takeEvery('GET_ALL_CATEGORY', getAllCategory),
    
  ]);
}