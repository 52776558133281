export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});

export const setLogin = (data) => ({
  type: 'SET_LOGIN',
  payload: data,
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

// QUIZ

export const setQuiz = (data) => ({
  type: 'SET_QUIZ',
  payload: data,
});

export const editQuiz = (data) => ({
  type: 'EDIT_QUIZ',
  payload: data,
});

export const getQuiz = (data) => ({
  type: 'GET_QUIZ',
  payload: data,
});

export const getAllQuiz = (data) => ({
  type: 'SET_ALL_QUIZ',
  payload: data,
});

export const deleteQuiz = (data) => ({
  type: 'DELETE_QUIZ',
  payload: data,
});

export const getQuizQuestions = (data) => ({
  type: 'GET_QUIZ_QUESTIONS',
  payload: data,
});

export const editQuizQuestions = (data) => ({
  type: 'EDIT_QUIZ_QUESTIONS',
  payload: data,
});

export const setQuizQuestions = (data) => ({
  type: 'SET_QUIZ_QUESTIONS',
  payload: data,
});

export const addQuizQuestions = (data) => ({
  type: 'ADD_QUIZ_QUESTIONS',
  payload: data,
});

export const deleteQuizQuestions = (data) => ({
  type: 'DELETE_QUIZ_QUESTIONS',
  payload: data,
});

// TUTORS

export const setTutors = (data) => ({
  type: 'SET_TUTORS',
  payload: data,
});

export const getTutors = (data) => ({
  type: 'GET_TUTORS',
  payload: data,
});

// CATEGORIES

export const setCategory = (data) => ({
  type: 'SET_CATEGORY',
  payload: data,
});

export const getCategory = (data) => ({
  type: 'GET_CATEGORY',
  payload: data,
});

export const getAllCategory = (data) => ({
  type: 'GET_ALL_CATEGORY',
  payload: data,
});

export const updateCategory = (data) => ({
  type: 'UPDATE_CATEGORY',
  payload: data,
});

export const deleteCategory = (data) => ({
  type: 'DELETE_CATEGORY',
  payload: data,
});