import ReactGA from 'react-ga';
import React, { Component } from 'react';

import './index.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            // <div className='footer'>
            <footer>
                <div class="container">
                    <div class="inner">
                        <div class="logo-copyright">
                            <a class="logo" href="">
                                <img src="images/logo-white.png" alt="" />
                            </a>

                            <p>Copyright © 2023 Blockate</p>
                            <p>All rights reserved.</p>
                        </div>

                        <ul>
                            <li><a href="">Home</a></li>
                            <li><a href="">Courses</a></li>
                            <li><a href="">About us</a></li>
                            <li><a href="">Contact us</a></li>
                        </ul>

                        <div class="subscribe">
                            <h3>Subscribe to Newsletter</h3>

                            <form action="">
                                <input type="email" placeholder="Email" />

                                <button type="submit">Subscribe</button>
                            </form>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="footer-widget social-widget">
                                <ul>
                                    <li><a href="https://twitter.com/learn2earn" target="_blank"> <i className="icon"><img src={require('../../static/images/landing/footer-twitter.png')} alt='' /></i> </a></li>
                                    <li><a href="https://discord.gg/tg82Y7ZzZ6" target="_blank"> <i className="icon"><img src={require('../../static/images/landing/footer-discord.png')} alt='' /></i> </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="footer-widget copyright-widget">

                                <p>© 2023 Learn 2 Earn</p>

                            </div>
                        </div>
                    </div>
                </div> */}
                </div >
            </footer>
        );
    }
}

export default Footer;