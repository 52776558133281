import { PURGE } from "redux-persist";

let initialState = {
  token: localStorage.getItem("token"),
  isLogin: localStorage.getItem("token") ? true : false,
  availableCourses: [],
  courseDetails: [],
  authorCourses: [],
  getQuiz: [],
  quizQuestions: [],
  tutors:[],
  categories:[],
  setLoader: { message: 'Please Wait...', status: false },
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'SET_LOGIN':
      localStorage.setItem('token', payload);
      return {
        ...state,
        token: payload,
        isLogin: true
      };

    case 'LOGOUT':
      localStorage.removeItem('token');
      return {
        ...state,
        token: '',
        isLogin: false
      };

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    /*========== ASSESSMENT REDUCERS ============= */

    case "SET_RESULT":
      localStorage.setItem("userStatus", payload);
      return {
        ...state,
        userStatus: payload,
      };

    case "SET_COURSES":
      return {
        ...state,
        availableCourses: payload,
      };

    case "SET_COURSE":
      return {
        ...state,
        courseDetails: payload,
      };

    case "SET_AUTHOR_COURSES":
      return {
        ...state,
        authorCourses: payload,
      };

    // Quiz

    case "GET_QUIZ":
      return {
        ...state,
        getQuiz:payload,
      }

    case "SET_QUIZ_QUESTIONS":
      return {
        ...state,
        quizQuestions:payload,
      }

    case "SET_TUTORS":
      return {
        ...state,
        tutors:payload
      }

    case "GET_CATEGORY":
      return {
        ...state,
        categories:payload
      }
    default:
      return state;
  }
};

export default Auth;