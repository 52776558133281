import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import {Link}  from 'react-router-dom';
import { Modal } from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { toggleLoader, getAllQuiz, deleteQuiz, editQuiz} from "../../store/actions/Auth";

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      isOpen: false,
      setQuiz:"",
      setPassingPercentage:0,
      quizId:"",
      createQuizModal: false,
    };
  }

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  componentDidMount(){
    this.props.toggleLoader({message:"Get quiz...", status: true});
    this.props.getAllQuiz({})
  }

  deleteQuizById = (id) => {
    this.props.toggleLoader({message:"Delete quiz...", status: true});
    this.props.deleteQuiz({id})
  }

  editQuizModel = (setQuiz,setPassingPercentage,quizId) => {
    this.setState({setQuiz,setPassingPercentage,quizId,createQuizModal:true})
  }

  updateItem = () => {
    let {setQuiz,setPassingPercentage,quizId} = this.state;
    this.props.toggleLoader({message:"Quiz updating...", status: true});
    this.props.editQuiz({setQuiz,setPassingPercentage,quizId});
  }

  componentDidUpdate(prevProps,prevState){
    let {getQuiz} = this.props;
    if(prevProps.getQuiz !== getQuiz){
        this.setState({
            createQuizModal: false
        });
    }
  }

  render() {
    let { getQuiz, categories } = this.props;
    let { setQuiz, setCategory, setPassingPercentage} = this.state;

    return (
      <>
              
        <Modal
                    open={this.state.createQuizModal}
                    onClose={() => this.setState({createQuizModal:false})}
                    classNames={{
                        modal: `common-modal`,
                    }}
                    center
                    >
                <div class="modal-header">
                    <button type="button" class="close" onClick={() => this.setState({createQuizModal:false})}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="form-main">
                            <div class="inner">
                                <h1>Quiz details</h1>
                                <form>
                                    <div class="form-group dropdown">
                                        <select class="form-control" value={setQuiz} onChange={e=>this.setState({setQuiz:e.target.value})}>
                                            {categories && categories.length > 0 &&
                                                categories.map(items=>
                                                    <option value={items['_id']}>{items['categoryName']}</option>
                                            )}
                                        </select>
                                    </div>
                                    
                                    {/* <div class="form-group dropdown">
                                        <select class="form-control" value={setCategory} onChange={e=>this.setState({setCategory:e.target.value})}>
                                            <option value="" selected disabled>Select category</option>
                                            <option value="Beginner">Beginner</option>
                                            <option value="Intermediate">Intermediate</option>
                                            <option value="Expert">Expert</option>
                                        </select>
                                    </div> */}

                                    <div class="form-group">
                                      <input type="number" placeholder="Please add passing percentage like 80" value={setPassingPercentage} onChange={e=>this.setState({setPassingPercentage:e.target.value})} />
                                        {/* <select class="form-control" value={setPassingPercentage} onChange={e=>this.setState({setPassingPercentage:e.target.value})}>
                                            <option value="" selected disabled>Select passing percentage</option>
                                            <option value="Beginner">Beginner</option>
                                            <option value="Intermediate">Intermediate</option>
                                            <option value="Expert">Expert</option>
                                        </select> */}
                                    </div>

                                    <div class="form-group">
                                        <button type="button" className="common-btn" onClick={() => this.updateItem()}>
                                            Update
                                        </button>
                                    </div>
                                </form>
                            </div>
                    </div>
                </div>
        </Modal>
      <div className="wrapper" onWheel={this.onScroll}>
      
        <Navbar/>

        <div className="admin-wrapper">
          <Sidebar/>

          <div className="inner">
            <div className="table-wrap">
              <table responsive="md">
                <thead>
                  <tr>
                    <th>Quiz</th>
                    {/* <th>Category</th>? */}
                    <th>Passing Percentage</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {getQuiz && getQuiz.length > 0 && getQuiz.map(item=>
                  <tr>
                    <td>{item.setQuiz?.['categoryName']}</td>
                    <td>{item['setPassingPercentage']}</td>
                    <td>
                      <button onClick={()=>this.editQuizModel(item['setQuiz']['_id'],item['setPassingPercentage'],item['_id'])}>Edit</button>
                      <button className="dlt" onClick={()=>this.deleteQuizById(item['_id'])}>Delete</button>
                      <Link to={`/edit-questions/${item['_id']}`}>Questions</Link>
                    </td>
                  </tr>
                  )}
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
      </>

    );
  }
}

const mapDispatchToProps = { toggleLoader, getAllQuiz, deleteQuiz, editQuiz};

const mapStateToProps = ({ Auth }) => {
  let {getQuiz,categories} = Auth;
  return {getQuiz,categories};
};

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
