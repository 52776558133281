import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { useState, Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import './index.css';
import { login, setQuiz, toggleLoader, setCategory, getAllCategory } from '../../store/actions/Auth';
const correct = "/images/correct-icon.png";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            setQuiz:"",
            setPassingPercentage:80,
            category:"",
            quizs:[{ 
                question:"",
                answerA:"",
                answerB:"",
                answerC:"",
                answerD:"",
                correctAnswer:""
              }],
              createQuizModal: false,
              createCategoryModal: false,
        };
    };

    addInputField = ()=>{
        let {quizs} = this.state;
        this.setState({quizs:[...quizs, { 
            question:"",
            answerA:"",
            answerB:"",
            answerC:"",
            answerD:"",
            correctAnswer:""
        }]})
    }

    removeFormFields = (index)=>{
        let {quizs} = this.state;
        const rows = [...quizs];
        rows.splice(index, 1);
        this.setState({quizs:rows})
    }

    handleChangeForm = (evnt,index)=>{
        let {quizs} = this.state;
        const { name, value } = evnt.target;
        const list = [...quizs];
        list[index][name] = value;
        this.setState({quizs:list})
    }
    
    publishQuiz = () => {
        let {setQuiz, setPassingPercentage,quizs} = this.state;
        console.log("*************** setQuiz",setQuiz);
        if(!setQuiz && !setQuiz.trim("") == true) return EventBus.publish("error", "Please select quiz");
        if(!setPassingPercentage && !setPassingPercentage.trim("") == true) return EventBus.publish("error", "Please add passing percentage");
        if(quizs.length == 0) return EventBus.publish("error", "Please add quiz questions");
        for (let index = 0; index < quizs.length; index++) {
            const element = quizs[index];
            if(!element['question'] && !element['question'].trim("") == true) return EventBus.publish("error", `Please add question no ${index+1}`);
            if(!element['answerA'] && !element['answerA'].trim("") == true) return EventBus.publish("error", `Please add option A of question ${index+1}`);
            if(!element['answerB'] && !element['answerB'].trim("") == true) return EventBus.publish("error", `Please add option B of question ${index+1}`);
            if(!element['answerC'] && !element['answerC'].trim("") == true) return EventBus.publish("error", `Please add option C of question ${index+1}`);
            if(!element['answerD'] && !element['answerD'].trim("") == true) return EventBus.publish("error", `Please add option D of question ${index+1}`);
            if(!element['correctAnswer'] && !element['correctAnswer'].trim("") == true) return EventBus.publish("error", `Please add correct option of question ${index+1}`);
        }
        this.props.toggleLoader({message:"Quiz submitting...", status: true});
        this.props.setQuiz({setQuiz, setPassingPercentage,quizs});
    }

    componentDidUpdate(prevProps,prevState){
        let {getQuiz, categories} = this.props;
        if(prevProps.getQuiz !== getQuiz){
            this.setState({
                isOpen: false,
                setQuiz:"",
                setPassingPercentage: 80,
                quizs:[{ 
                    question:"",
                    answerA:"",
                    answerB:"",
                    answerC:"",
                    answerD:"",
                    correctAnswer:""
                  }],
                  createQuizModal: false,
                  createCategoryModal: false,
                  mcqsSetupModal:false
            });
        }
        if(prevProps.categories !== categories && categories.length > 0) this.setState({createCategoryModal:false, setQuiz:categories[0]["_id"]})
    }

    componentDidMount(){
        this.props.getAllCategory({})
    }

    addCategory = async () => {
        let {category} = this.state;
        if(!category.trim() ==  true) return EventBus.publish("error", "Please add category");
        this.props.toggleLoader({message:"Category submitting...", status: true});
        this.props.setCategory({categoryName:category})
    }

    render() {
        let {quizs,setQuiz,setPassingPercentage} = this.state;
        let {categories} = this.props;

        return (
            <header>
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <a class="logo" href="/">
                            <img src="/images/logo.png" alt="" />
                        </a>

                        <a onClick={() => this.setState({createCategoryModal:true})} className="common-btn">
                            Add Category
                        </a>

                        <a onClick={() => this.setState({createQuizModal:true})} className="common-btn">
                            Create Quiz
                        </a>

                        <div className="profile">
                            <a href="">
                                <span>Super Admin</span>

                                {/* <img src="/images/profile.png" alt="" /> */}
                            </a>
                        </div>
                    </nav>
                </div>

                <Modal
                    open={this.state.createQuizModal}
                    onClose={() => this.setState({createQuizModal:false})}
                    classNames={{
                        modal: `common-modal`,
                    }}
                    center
                    >
                <div class="modal-header">
                    <button type="button" class="close" onClick={() => this.setState({createQuizModal:false})}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="form-main">
                            <div class="inner">
                                <h1>Quiz details</h1>
                                <form>
                                    <div class="form-group dropdown">
                                        <select class="form-control" value={setQuiz} onChange={e=>this.setState({setQuiz:e.target.value})}>
                                            {categories && categories.length > 0 &&
                                                categories.map(items=>
                                                    <option value={items['_id']}>{items['categoryName']}</option>
                                            )}
                                            {/* <option value="Javascript">Javascript</option>
                                            <option value="Blockchain">Blockchain</option>
                                            <option value="Information-Technology">Information Technology</option> */}
                                        </select>
                                    </div>
                                    
                                    {/* <div class="form-group dropdown">
                                        <select class="form-control" value={setCategory} onChange={e=>this.setState({setCategory:e.target.value})}>
                                            <option value="" selected disabled>Select category</option>
                                            <option value="Beginner">Beginner</option>
                                            <option value="Intermediate">Intermediate</option>
                                            <option value="Expert">Expert</option>
                                        </select>
                                    </div> */}

                                    <div class="form-group">
                                        <input type="number" placeholder="Please add passing percentage like 80" defaultValue={setPassingPercentage} onChange={e=>this.setState({setPassingPercentage:e.target.value})} />
                                        {/* <select class="form-control" value={setPassingPercentage} onChange={e=>this.setState({setPassingPercentage:e.target.value})}>
                                            <option value="" selected disabled>Select passing percentage</option>
                                            <option value="Beginner">Beginner</option>
                                            <option value="Intermediate">Intermediate</option>
                                            <option value="Expert">Expert</option>
                                        </select> */}
                                    </div>

                                    <div class="form-group">
                                        <button type="button" className="common-btn" onClick={() => this.setState({createQuizModal:false, mcqsSetupModal:true})}>
                                            Continue
                                        </button>
                                    </div>
                                </form>
                            </div>
                    </div>
                </div>
                </Modal>

                <Modal
                    open={this.state.createCategoryModal}
                    onClose={() => this.setState({createCategoryModal:false})}
                    classNames={{
                        modal: `common-modal`,
                    }}
                    center
                    >
                <div class="modal-header">
                    <button type="button" class="close" onClick={() => this.setState({createCategoryModal:false})}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="form-main">
                            <div class="inner">
                                <h1>Add Category</h1>
                                <form>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Category Name"  onChange={e=>this.setState({category:e.target.value})}/>
                                    </div>

                                    <div class="form-group">
                                        <button type="button" className="common-btn" onClick={() => this.addCategory()}>
                                            Continue
                                        </button>
                                    </div>
                                </form>
                            </div>
                    </div>
                </div>
                </Modal>

                <Modal
                    open={this.state.mcqsSetupModal}
                    onClose={() => this.setState({mcqsSetupModal:false})}
                    classNames={{
                        modal: `common-modal mcqs-setup`,
                    }}
                    center
                    >
                <div class="modal-header">
                    <button type="button" class="close" onClick={() => this.setState({mcqsSetupModal:false})}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="form-main">
                            <div class="inner">
                                    <div className="head">
                                        <div className="empty"></div>

                                        <div className="title-wrap">
                                            <h1>Add MCQs</h1>

                                            <p>Select correct option before adding question to the quiz.</p>
                                        </div>

                                        <button className="common-btn" onClick={()=>this.publishQuiz()}>Publish quiz</button>
                                    </div>
                                    <div className="row-wrap">
                                    {quizs.map((data, index)=>{
                                    const {question,answerA, answerB, answerC, answerD, correctAnswer}= data;
                                    return(
                                        <div className="row" key={index}>
                                             <div class="form-group col-12">
                                                            <h5 style={{color:"white"}}>Question No {index+1}</h5>
                                                </div>
                                            <div class="form-group col-12">
                                                <textarea className="form-control" name="question" value={question || ""} onChange={(e)=>this.handleChangeForm(e,index)} placeholder="Write question here"></textarea>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="A">A</label>
                                                <input id="A" type="text" class="form-control" placeholder="Write option A here" name="answerA" value={answerA || ""} onChange={(e)=>this.handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="B">B</label>
                                                <input id="B" type="text" class="form-control" placeholder="Write option B here" name="answerB" value={answerB || ""} onChange={(e)=>this.handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="C">C</label>
                                                <input id="C" type="text" class="form-control" placeholder="Write option C here" name="answerC" value={answerC || ""} onChange={(e)=>this.handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="D">D</label>
                                                <input id="D" type="text" class="form-control" placeholder="Write option D here" name="answerD" value={answerD || ""} onChange={(e)=>this.handleChangeForm(e,index)}/>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="E"><img className="blue" src={correct}/></label>
                                                <select id="E" class="form-control" name="correctAnswer" value={correctAnswer || ""} onChange={(e)=>this.handleChangeForm(e,index)}>
                                                    <option value="" selected disabled>Select correct option</option>
                                                    <option value={answerA}>{answerA}</option>
                                                    <option value={answerB}>{answerB}</option>
                                                    <option value={answerC}>{answerC}</option>
                                                    <option value={answerD}>{answerD}</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6 d-flex justify-content-end align-items-center">
                                                {(quizs.length !== 1) ? <button className="delete-btn common-btn"  onClick={()=>this.removeFormFields(index)}>Delete question</button>:""}
                                            </div>
                                        </div>
                                    )
                                    })}
                                    </div>
                                    
                                    

                                    <div className="bottom flex-center">
                                        <button className="common-btn" onClick={this.addInputField}>Add question</button>
                                    </div>
                            </div>
                    </div>
                </div>
                </Modal>
      </header>
        );
    }
}

const mapDispatchToProps = {
    login,setQuiz,toggleLoader, setCategory, getAllCategory
};

const mapStateToProps = ({ Auth }) => {
    let { getQuiz, categories } = Auth;
    return { getQuiz, categories }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);