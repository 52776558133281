import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import {Link}  from 'react-router-dom';
import { Modal } from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import "../../static/css/animate.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { toggleLoader, getAllQuiz, deleteQuiz, editQuiz, getAllCategory, deleteCategory, updateCategory} from "../../store/actions/Auth";

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
      isOpen: false,
      categoryName:"",
      _id:"",
      setQuiz:"Javascript",
      setPassingPercentage:0,
      quizId:"",
      createCategoryModal: false,
    };
  }

  onScroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset > 20) this.setState({ sticky: true });
    if (pageYOffset < 20) this.setState({ sticky: false });
  };

  componentDidMount(){
    this.props.toggleLoader({message:"Get categories...", status: true});
    this.props.getAllCategory({})
  }

  editCategoryModel = (_id,categoryName) => {
    this.setState({_id,categoryName,createCategoryModal:true})
  }

  updateItem = () => {
    let {_id,categoryName} = this.state;
    this.props.toggleLoader({message:"Category updating...", status: true});
    if(!categoryName.trim() ==  true) return EventBus.publish("error", "Please add category");
    this.props.updateCategory({_id,categoryName});
  }

  deleteCategoryById = (_id) => {
    this.props.toggleLoader({message:"Category deleting...", status: true});
    this.props.deleteCategory({_id})
  }

  componentDidUpdate(prevProps,prevState){
    let {categories} = this.props;
    if(prevProps.categories !== categories){
        this.setState({
          createCategoryModal: false
        });
    }
  }

  render() {
    let { categories } = this.props;
    let { categoryName } = this.state;

    return (
      <>
              
        <Modal
                    open={this.state.createCategoryModal}
                    onClose={() => this.setState({createCategoryModal:false})}
                    classNames={{
                        modal: `common-modal`,
                    }}
                    center
                    >
                <div class="modal-header">
                    <button type="button" class="close" onClick={() => this.setState({createCategoryModal:false})}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="form-main">
                            <div class="inner">
                                <h1>Edit Category</h1>

                                <form>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Category Name"  defaultValue={categoryName} onChange={e=>this.setState({categoryName:e.target.value})}/>
                                    </div>

                                    <div class="form-group">
                                        <button type="button" className="common-btn" onClick={() => this.updateItem()}>
                                            Update
                                        </button>
                                    </div>
                                </form>
                            </div>
                    </div>
                </div>
        </Modal>
      <div className="wrapper" onWheel={this.onScroll}>
      
        <Navbar/>

        <div className="admin-wrapper">
          <Sidebar/>

          <div className="inner">
            <div className="table-wrap">
              <table responsive="md">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {categories && categories.length > 0 &&
                  categories.map((item,index)=>
                  <tr key={index}>
                    <td>{item['categoryName']}</td>
                    <td>
                      <button onClick={()=>this.editCategoryModel(item["_id"], item['categoryName'])}>Edit</button>
                      <button className="dlt" onClick={()=>this.deleteCategoryById(item["_id"])}>Delete</button>
                    </td>
                  </tr>
                  )
                  }
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
      </>

    );
  }
}

const mapDispatchToProps = { toggleLoader, getAllQuiz, deleteQuiz, editQuiz,getAllCategory, deleteCategory, updateCategory};

const mapStateToProps = ({ Auth }) => {
  let {getQuiz,categories} = Auth;
  return {getQuiz,categories};
};

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
