import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Outlet, useLocation, useHistory} from "react-router-dom";
import { logout } from '../../store/actions/Auth';
const Sidebar = (props) => {

    const history = useHistory();

    const LogoutUser = () => {
        props.logout();
        history.push('/home');
        window.location.reload();
    };

    return (
        <>
        <div className='sidebar'>
            <ul>
                <li className="nav-item">
                    <NavLink  to="/tutors-list">
                        <img src="/images/tutors.png"/>
                        <img className="blue" src="/images/tutors-blue.png"/>

                        Tutors List
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink  to="/quiz">
                        <img src="/images/quiz-icon.png"/>
                        <img className="blue" src="/images/quiz-icon-blue.png"/>

                        Quiz
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink  to="/category">
                        <img src="/images/quiz-icon.png"/>
                        <img className="blue" src="/images/quiz-icon-blue.png"/>

                        Category
                    </NavLink>
                </li>

                {/* <li className="nav-item">
                    <NavLink  to="/edit-quiz">
                        <img src="/images/quiz-icon.png"/>
                        <img className="blue" src="/images/quiz-icon-blue.png"/>

                        Edit Quiz
                    </NavLink>
                </li> */}
            </ul>

            <ul>
                <li className="nav-item">
                    <button className="logout"  onClick={()=>LogoutUser()}>
                        <img src="/images/logout.png"/>
                        <img className="blue" src="/images/logout-blue.png"/>
                        
                        Logout
                    </button>
                </li>
            </ul>
        </div>
        
        </>
    );
}


const mapDispatchToProps = {
    logout
};

const mapStateToProps = ({ Auth }) => {
    let {  } = Auth;
    return {  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);