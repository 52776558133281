import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import SignIn from './Learn2Earn/sign-in.js';
import Learn2Earn from './Learn2Earn';
import Quiz from './Learn2Earn/quiz.js';
import Category from './Learn2Earn/category.js';
import EditQuiz from './Learn2Earn/edit-quiz.js';
import EditQuestions from './Learn2Earn/edit-questions.js';
import Statistics from './Learn2Earn/statistics';

import Loader from '../components/loader';
import { logout, login } from '../store/actions/Auth';
import ProtectedRoute from './ProtectedRoute';

import '../static/css/style.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";

const hist = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: this.props.isLogin
    }
  };

  verifyTokenExpire = () => {
    const decoded = jwt_decode(this.props.token);
    const currentDate = new Date();
    const expirationTimestamp = decoded.exp * 1000;
    if (currentDate.getTime() > expirationTimestamp) {
      this.setState({isAuthenticated:false})
    } else {
      this.setState({isAuthenticated:true})
    }
  }

  componentDidMount() {
    if(this.props.token){
      this.verifyTokenExpire()
    }
    EventBus.on('tokenExpired', () => this.props.logout());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  };

  componentDidUpdate(prevProps,prevState){
    if(prevProps.token !== this.props.token){
      if(this.props.token){
        this.verifyTokenExpire()
      }
    }
  }

  render() {
    let {isAuthenticated} = this.state;
    return (
      <div>
        <Loader />
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />

        <Router history={hist}>
          <Switch>
            <Route exact path='/' component={props => !isAuthenticated ? <SignIn {...props} /> : <Learn2Earn {...props} />} />
            <ProtectedRoute path="/home" component={(props) => <Learn2Earn {...props} />} isAuthenticated={isAuthenticated}/>
            <ProtectedRoute path="/tutors-list" component={(props) => <Learn2Earn {...props} />} isAuthenticated={isAuthenticated} />
            <ProtectedRoute path="/quiz" component={(props) => <Quiz {...props} />} isAuthenticated={isAuthenticated} />
            <ProtectedRoute path="/category" component={(props) => <Category {...props} />} isAuthenticated={isAuthenticated} />
            <ProtectedRoute path="/edit-quiz" component={(props) => <EditQuiz {...props} />} isAuthenticated={isAuthenticated} />
            <ProtectedRoute path="/edit-questions/:id" component={(props) => <EditQuestions {...props} />} isAuthenticated={isAuthenticated} />
            <ProtectedRoute path="/statistics" component={(props) => <Statistics {...props} />} isAuthenticated={isAuthenticated} />
            <Route path="*" render={() => <Redirect to="/home" />} />
          </Switch>
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = {
  logout, login
};

const mapStateToProps = ({ Auth }) => {
  let { isLogin, token } = Auth;
  return { isLogin, token }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);